/** 套餐卡编辑界面 */
.mod-card-form .mod-time-card-form-side{width:240px;float:left}
.mod-card-form .mod-time-card-form-main{margin-left:230px;}
.mod-card-form .mod-time-card-form-body{padding:10px;}
.mod-card-form .mod-time-card-form-main .gra-table{}


.oc-root-view {
    width: 100%;
    height: 100%;
}

.oc-father {
    border-radius: 5px;
    border: solid #cccccc 1px;
}

.oc-left-view {
    border-radius: 5px 0 0 5px;
    border-right: solid #ccc 1px;
    padding: 20px 0px 0px 20px;
}

.oc-right-view {
    padding: 0px 20px 20px 20px;
    /*border-radius: 0 5px 5px 0px;*/
    /*border-right: solid #ccc 1px;*/
    width: 50%;
    height: 100%;
}

.oc-right-view-titel {
    padding: 10px;
    border-radius: 0 5px 5px 0px;
    border-bottom: solid #ccc 1px;
}

.oc-right-view-content {
    padding: 10px;
}

.oc-service-object {
    width: 70%;
    height: 90px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.oc-right-table {
    margin-top: 10px;
    border-radius: 5px;
    border: solid #ccc 1px;

}

.marign-top-5 {
    margin-top: 5px;
}

.oc-back-view {
    height: 50px;
    border-bottom: solid #ccc 1px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.oc-vip-type {
    padding-top: 10px;
    margin-top: 10px;
    border-top: dashed #ccc 1px;

}

.oc-vip-dashed {
    height: 10px;
    width: 100%;
    border-bottom: dashed #ccc 1px;
}