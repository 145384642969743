


/*进销报表*/
.vip-main-view{
    width: 100%;
    height: 100%;
    position: relative;
}

.vip-main-view-title{
    width: 100%;
    height: 110px;

}

.vip-main-view-content{
    position: absolute;
    top: 50px;
    left: 0px;
    bottom: 0px;
    overflow: auto;
}

.importButton{
    border: 1px #ccc solid;
    float: left;
    padding: 5px 20px;
    margin-right: 20px;
    border-radius: 6px
}
.importButtonActive{
    color: #fff;
    background: #fc1a6e
}


