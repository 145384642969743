.regist_root {
    padding-Top: 50px;
    width:400px
}

.padding {
    padding: 0 40px;
}

.borderNone {
    border: none;
    height: 50px;
}
.back {
    margin-top: 50px;

}

.color {
    color: #666
}

.setting_title {
    margin-left: 10px;
    font-size: 12px;
    color: #2C353A
}
