
.help-content{
    width: 200px;
}

.help-content .text{
    width: 100%;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
}
.help-all{
    height: 20px;
    width: 100%;
    position: relative;
}
.help-all a{
    /* float: right; */
}

.help-pop-red{
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    right: 10px;
    top: 5px;
    columns: #666666;
    background-color: #fa141b;
    font-size: 10px;
    color: white;
    
}

/*.help-content{*/
    /**/
/*}*/