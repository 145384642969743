@import "base.css";


.main_color{
    color: #FC1A6E;
}

.main_bg_color{
    background: #FC1A6E;
}

#root{border:solid 0px #666;background-size:cover;background-attachment: fixed;border-radius: 0;}
.ant-calendar-picker{width:240px;}

/* .mod-pg-dash{background:#fff;height:100%;}
.mod-pg-dash .gra-pg-title{position: relative;background:#FFF;border-bottom:solid 1px #CCC;z-index: 999;} */
/* .mod-pg-dash .gra-pg-title .ant-menu{margin-bottom:-1px;margin-left:20px;line-height:46px;border-color:#CCC} */
/** windows交通灯 */
/* .mod-pg-frame-dash-1 .gra-pg-title{padding-right:70px;}
.mod-pg-frame-dash-1{border:solid 1px #CCC;} */
/** linux交通灯 */
/* .mod-pg-frame-dash-2 .gra-pg-title,mod-pg-frame-dash-3 .gra-pg-title{padding-left:46px;}
.mod-pg-dash .gra-pg-title .gra-pg-title-space{position:absolute;top:0;left:380px;right:100px;background:#FFF;height:52px;opacity:0;}
.mod-pg-frame-dash-1 .gra-pg-title .gra-pg-title-space{right:200px;left:380px;}
.mod-pg-frame-dash-2 .gra-pg-title .gra-pg-title-space,.mod-pg-frame-dash-3 .gra-pg-title .gra-pg-title-space{left:480px;}
.mod-pg-dash .gra-pg-title .ant-dropdown-trigger{padding:0.5em 1em;color:#666;}
.mod-pg-dash .gra-pg-title .ant-menu-submenu-active{border-bottom:0;}
.mod-pg-dash .mod-traffic-light{top:5px;} */

/** 工作区 */
/* .mod-work-space{margin:0px 5px;min-width:800px;min-height:450px;height: calc(100% - 30px);overflow:hidden;background-color:"#fff"}
.mod-work-space .ant-layout{box-sizing: border-box;-webkit-box-sizing: border-box;background:#fff}
.mod-work-space .gra-layout-side{position:fixed;left:0;top:44px;bottom:0;width:140px;background:"#fff";border-right: solid 1px #e5e5e5;}
.mod-work-space .gra-layout-side .ant-menu-root{background:#fff;border-right:0;}
.mod-work-space .gra-layout-side .ant-menu-root{background:#fff;border-right:0;}
.mod-work-space .gra-layout-body{margin-left:120px;background:#fff} */

/* 表格 */
.mod-table-view{
    /* position: absolute;
    height: 70%;
    width: calc(100% - 180px) ;
    overflow: auto; */
}

.mod-table-view .mod-table{
    /* position: absolute;
    height: 70%;
    width: calc(100% - 180px) ;
    overflow: auto; */
}

/* .mod-table-view  .ant-pagination{
    position: fixed;
    right: 25px;
    bottom: 0px;
}  */


.gra-layout-body .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    margin-right:0px;
}


/** 工作区左侧菜单 */
/* .gra-layout-side .ant-menu-root li{border-bottom:solid 1px #CCC;margin:0;border-right:0;width:100%;color:#333;}
.gra-layout-side .ant-menu-item:after{display:none;}
.gra-layout-side .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{background:#FFF;color:#666;}
.gra-layout-side .ant-menu-vertical .ant-menu-item:not(:last-child),
.gra-layout-side .ant-menu-vertical-left .ant-menu-item:not(:last-child),
.gra-layout-side .ant-menu-vertical-right .ant-menu-item:not(:last-child),
.gra-layout-side .ant-menu-inline .ant-menu-item:not(:last-child){margin-bottom:0;} */

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.home-title-text-div {
    z-index: 999;
}
.home-title-text-div a:hover {
    background: #F3F4F6;
}

.home-title-text-view {
    height: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin-left: 10px;
    padding: 0px 10px;
    color: #666;
    z-index: 99;
    /*color: #fc1a6e;*/
}
.home-title-text-view:hover{color:#666;}

body {
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;
}

.ant-layout-header .mod-traffic-light{top:8px;}




.loading-class{
    position: absolute;
    top: 10%;
    left: 50%;
    z-index: 2000;
}


.order-view{
    position: relative;

}
.order-text{
    width: 20px;
    height: 20px;
    position: absolute;
    background: red;
    color: white;
    font-size: 10px;
    top: 5px;
    right: -18px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.message-text{
    width: 12px;
    height:12px;
    position: absolute;
    background: #FC1A6E;
    color: white;
    font-size: 8px;
    top: 5px;
    right: 0px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.share-text{
    width: 20px;
    height: 20px;
    position: absolute;
    background: red;
    color: white;
    font-size: 10px;
    top: -5px;
    right: -18px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.float-menu-icon{
    position: relative;
    margin-top: -10px;
}



/*进货管理*/
.table-sliding-main-view{
    width: 100%;
    height: 100%;
    position: relative;
}

.table-sliding-view-title{
    width: 100%;
    height: 50px;

}

.table-sliding-view-content{
    position: absolute;
    top: 50px;
    left: 15px;
    right: 15px;
    bottom: 0px;
    overflow: auto;
}

/*进货管理*/
.app-sliding-main-view{
    width: 100%;
    height: 100%;
    position: relative;
}

.app-sliding-view-content{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: auto;
}


.app-sliding-div{
    height: 100px;
    width: 100%;
    overflow: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.service-main-view-content{
    position: absolute;
    top: 60px;
    left: 15px;
    right: 15px;
    bottom: 0px;
    overflow: auto;
}

.bar-style{
    height: 150px;
    width:100%;
    margin-top:"-3px";
    border: '1px #ccc solid';
    background: '#ededed';
}
.bar-hint-text{
    height:20px;
    font-size:14px;
    font-family:PingFangSC-Regular;
    font-weight:400;
    color:#999;
    line-height:20px;
    margin: 15px 20px 8px 0px;
  }
  .bar-item-text{
    padding: 5px 15px;
    background:#fff;
    border-radius:6px;
    margin-right: 20px;
    position: relative;
  }

  .bar-item-icon{
    width:16px;
    height:16px;
    background:#CECECE;
    border-radius:8px;
    position: absolute;
    top: -5px;
    right: -5px;

  }

.bar-nexe-view{
    border-top: solid 1px  #e1e1e1;
    height: 100%;
    width: 100vw;
    background: #00000000;
}
.idrawer-view{
    height:calc(100vh - 105px);
    /*position: relative;*/
    overflow: auto;
}
.idrawer-content{
    overflow: auto;
    height: 100%;
}
.idrawer-content-footer{
    overflow: auto;
    height: calc(100% - 40px);
}
.idrawer-footer{
    border-top: solid 1px  #ccc;
    position: absolute;
    height: 60px;
    right: 0px;
    width: 100%;
    bottom: 0px;
    background: white;
}


.idrawer-footer-view{
   float: right;
    margin-top: 12px;
}

.panel-html p{
    height:auto;
    /*width:350px;*/
    word-break: break-all;
}

.app-massage-view{

    position: relative;

}

.app-massage-star{
    position: absolute;
    color: red;
    top: 0px;
    right: 0px;
    font-size: 50px;
}


.app-border{
    border: solid 1px #ccc;
}

#mzl-time-over{
    position: absolute;
    top: 48px;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: 999;
}

#mzl-time-over .mzl-time-over-hint{
    padding: 10px 10px 10px 40px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    /* flex-direction: row;
    align-items: center; */
    border-bottom: solid 1px #ccc;
}
#mzl-time-over .mzl-time-over-footer{
    width: 100%;
    height: 100vh;
    background-color: #00000000;
}

body{
    margin: 0px;
    padding:0px;
}
@page  {
    margin: 0px;
    padding: 0px;
}

.log-view{
    /* padding: 2px 2px 2px 2px;
    background-color: #f9f9fa;
    box-sizing: border-box; */
  }
  
  .content-right-head{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
  }
  


  .app-left-content{
    overflow: auto;
    /* 隐藏滚动条 */
    scrollbar-width: thin;
    /* Webkit浏览器 (如Chrome、Safari)上隐藏滚动条 */
    scrollbar-color: transparent transparent;
  }
  
  /* Webkit浏览器上隐藏滚动条轨道 */
  .app-left-content::-webkit-scrollbar {
    width: 8px; /* 设置滚动条宽度 */
  }
  
  .app-left-content::-webkit-scrollbar-thumb {
    background-color: transparent; /* 设置滚动条拖动部分颜色 */
  }
  