@import "base.css";
body{background:#FFF;}
.mod-pg-login{
    width: 100%;
    height:100%;
    background: url('../images//loginbg.svg') no-repeat center center;
    background-size: auto 100%;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
.mod-pg-login .mod-traffic-light .max{display:none;}
/* @media(min-width:1200px) {
    .mod-pg-login{width:1200px;margin:0 auto;}
}*/

/* .login_root{
    width: 100%;
    height: 479px;
    position: relative;
    background: url(./bg.png) center center no-repeat;
} */
.login-form {
    width: 300px;
    /* position: absolute;
    top:80px;
    right:200px; */
}

/* .login-form-forgot {
   float: right;
 }
.login-form-button {
   width: 100%;
 } */

/** 客户端登陆样式 */
.mod-cli-login {
    background: #FFF;
    padding: 0 40px;
    padding-top: 50px;
    text-align: center;
    overflow: hidden;
    margin: 0;
}

.mod-cli-login input {
    -webkit-app-region: no-drag;
    -webkit-user-select: text;
}

.mod-cli-login input[type=text], .mod-cli-login input[type=password] {
    border-width: 0 0 1px 0;
    border-radius: 0;
    line-height: 60px;
    height: 60px;
    padding: 0;
    font-size: 16px;
}

.mod-cli-login input[type=text]:focus, .mod-cli-login input[type=password]:focus,
.mod-cli-login input[type=text]:hover, .mod-cli-login input[type=password]:hover {
    text-shadow: none;
    box-shadow: none;
    border-right: none;
}

.mod-cli-login .ant-form-item {
    margin-bottom: 0
}

.mod-cli-login .hidden {
    display: none;
}

.mod-cli-login .mod-cli-login-item {
    position: relative;
}

.mod-cli-login .ant-form-item-children {
    position: inherit
}

.mod-cli-login .mod-cli-login-submit {
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.mod-cli-login .mod-cli-login-go {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 40px;
    height: 40px;
    overflow: hidden;
    background: #FFF;
    text-align: right;
}

.mod-cli-login .mod-cli-login-submit {
    position: absolute;
    right: 0;
    width: 45px;
    height: 45px;
    opacity: 0;
    z-index: 2;
}

.clearfix {
    clear: both;
    height: 0;
    width: 0;
    overflow: hidden;
}

.clearfix:after {
    content: "."
}

/** 快速操作 */
.mod-login-quick {
    list-style: none;margin: 3em 0 0 0;padding: 0;
}

.mod-login-quick li {
    width: 50%;
    float: left;
    text-align: left;
    line-height: 2em;
    height:2em;
}

.mod-login-quick .right-item {
    text-align: right;
}

.mod-login-quick label {
    margin-left: 5px;
}

.save-user{
    display: flex;
    flex-direction: row;
    list-style: none;margin: 2em 0 0 0;padding: 0;
    width: 100%;
    height: 100%;
}

.login-user{
    width:100%;
    display: flex;
    flex-direction: column;
}


.login-pwd{
    width:100%;
    display: flex;
    flex-direction: column;
}



.login-user span {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-left: 5px;
}

.login-pwd span{
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: 5px;

}

.btn-login{
    width:100%;
    height:35px;
    margin-top: 20px;
    /* border-radius:24px; */
    color: white;
}


.account-hint{

}

.btn-register{
    width:100%;
    height:35px;
    margin-top: 10px;
    background:rgba(255,255,255,1) ;
    /* border-radius:24px; */
    /*border:1px solid #ccc;*/
}

.bottom-show-version{
    position: absolute;
    left: 0px;
    bottom: 5px;
    color:#999;
    font-size: 12px;
    width: 100%;
}