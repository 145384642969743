
.downStyle {
    width: 250px;
    height: 30px;
    border: solid #dcdcdc 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    padding: 0 10px;
}

.downIcon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.edit-view {
    width: 350px;
    position: relative;
}

.btn-edit {
    position: absolute;
    right: 0px;
    top: 0px;
}

.btn-edit-view {
    padding-left: 200px;
    padding-top: 40px;
    display: flex;
    flex-direction: row;
}
.spanstyle-shopinfo{
    width:66px;
    text-align:right;
}
.twoInput .downStyle{
    width:125px;

}
.comm-padding-top-18{
    padding-top:18px;
}

.app-logo-view{

    position: fixed;
    width: 200px;
    height: 200px;
    position: absolute;
    right: 0px;
    top: 100px;
}

.app-logo-img{
    width: 100px;
    /* height: 100px; */
}

.avatar-uploader > .ant-upload {
    /* width: 128px;
    height: 128px; */
  }