@import "imageSize.css";
body{border-radius:10px}
.comm-row {
    display: flex;
    flex-direction: row;
}
.flexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


.comm-col {
    display: flex;
    flex-direction: column;
}

.comm-item-left {
    align-items: left;
}


.comm-item-right {
    align-items: right;
}

.comm-item-center {
    align-items: center;
}

.comm-item-justify {
    justify-content: center;
}

.comm-item-justify-right {
    justify-content: flex-end;
}

.comm-item-justify-between {
    justify-content: space-between;
}
.comm-flex-row-between{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.comm-flex-col-between{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


.comm-flex-row-center{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.comm-flex-col-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.comm-relative{
    position: relative;
}

.comm-w-h{
    width: 100%;
    height: 100%;
}
.comm-w-h1{
    width: 100%;
    height: 90%;
}

.comm-w{
    width: 100%;
}
.comm-h{
    height: 100%;
}

.overflow-auto{
    overflow: auto;
}


html, body {
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
}

.comm-float-left {
    float: left;
}

.comm-line {
    width: 100%;
    height: 1px;
    background: #f5f5f5;
}

.comm-line-dc {
    width: 100%;
    height: 1px;
    background: #dcdcdc;
}

.comm-line-10 {
    width: 100%;
    height: 10px;
    background: #f5f5f5;
}

.comm-padding-10 {
    padding: 10px;
}

.comm-padding-left-10 {
    padding-left: 10px;
}

.comm-padding-top-10 {
    padding-top: 10px;
}

.comm-padding-right-10 {
    padding-right: 10px;
}

.comm-padding-bottom-10 {
    padding-bottom: 10px;
}

.comm-margin-left-10 {
    margin-left: 10px;
}

.comm-margin-top-10 {
    margin-top: 10px;
}

.comm-margin-right-10 {
    margin-right: 10px;
}

.comm-margin-bottom-10 {
    margin-bottom: 10px;
}

/** 底部分割线*/
.comm-line-bottom {
    border-bottom: solid 1px #ccc;
}

/** 隐藏布局*/
.comm-display-none {
    display: none;
}

/** 隐藏布局*/
.comm-visibility-hidden {
    visibility: hidden;
}

/** 显示布局*/
.comm-visibility-visible {
    visibility: visible;
}

.comm-text-b-w{
    color: white;
    background-color: #fc1a6e;
}


/** 默认字体颜色*/
/* span {
    color: #666;
} */
.comm-text-main {
    color: #fc1a6e;
}
.comm-text-white {
    color: #fff;
}

.comm-text333 {
    color: #333;
}

.comm-text666 {
    color: #666;
}

.comm-text999 {
    color: #999;
}

.comm-textf5f5f5 {
    color: #f5f5f5;
}

.comm-text00A699 {
    color: #00A699;
}

.comm-text-red {
    color: #ff6666;
}

.comm-text-violet {
    color: #fd5056;
}

.comm-text-dcdcdc {
    color: #dcdcdc;
}

.comm-text-767F88 {
    color: #767F88;
}

.comm-text-6699FF {
    color: #6699FF;
}

.comm-text-5CB85C {
    color: #5CB85C;
}


.comm-text-BFBFBF {
    color: #BFBFBF;
}

.comm-text-D9D9D9 {
    color: #d9d9d9;
}
.comm-text-FFAB81 {
    color: #FFAB81;
}
/**
 *默认的背景
 */
.comm-background {
    background-color: #f5f5f5;
}

.comm-border-solid-radius-5px {
    border: solid #ccc 1px;
    border-radius: 5px;
}

.comm-background-A44CFC {
    background-color: #fc1a6e;
}

.comm-background-f3f4f6 {
    background-color: #f3f4f6;
}

.comm-background-333 {
    background-color: #f3f4f6;
}

.comm-background-ccc {
    background-color: #cccccc;
}

.comm-background-f3f3f3 {
    background-color: #f3f3f3;
}

.comm-background-f5f5f5 {
    background-color: #f5f5f5;
}
.comm-background-ededed {
    background-color: #ededed;
}

.comm-background-white {
    background-color: white;
}

.comm-background-violet {
    background-color: #fc1a6e;
}

.comm-font-12 {
    font-size: 12px;
}

.comm-font-13 {
    font-size: 13px;
}

.comm-font-14 {
    font-size: 14px;
}

.comm-font-15 {
    font-size: 15px;
}

.comm-font-16 {
    font-size: 16px;
}

.comm-font-17 {
    font-size: 17px;
}

.comm-font-18 {
    font-size: 18px;
}

.comm-font-20 {
    font-size: 20px;
}

/** 文字一行显示 超出部分以...结尾 */
.comm-text-over {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.comm-head-img {
    height: 60px;
    width: 60px;
    border-radius: 50%;

}
@font-face {
    font-family: 'iconfont';  /* project id 564375 */
    src: url('//at.alicdn.com/t/font_564375_4j3fjbiatvv.eot');
    src: url('//at.alicdn.com/t/font_564375_4j3fjbiatvv.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_564375_4j3fjbiatvv.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_564375_4j3fjbiatvv.woff') format('woff'),
    url('//at.alicdn.com/t/font_564375_4j3fjbiatvv.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_564375_4j3fjbiatvv.svg#iconfont') format('svg');
}


.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
    content: "\e68a";
    color: #00A699;
    padding-left: 5px;
}

.icon-setting:before {
    content: "\e624";
    color: #666;
    font-size: 20px;
}

.icon-cika:before {
    content: "\e743";
    color: #fc1a6e;
    font-size: 16px;
}

.icon-vip:before {
    content: "\e681";
    color: #f0ad41;
    font-size: 16px;
}

.icon-guanbi1:before {
    content: "\e61b";
}

.comm-btn-confirm {
    height: 50px;
    width: 100%;
    background-color: #00A699;
    color: white;
    position: fixed;
    bottom: 0px;

}

.no-data {
    width: 100%;
    margin-top: 200px;
    text-align: center;

}

.comm-loading-div {
    margin-top: -70px;
    width: 100%;
    position: fixed;
    top: 255px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.comm-loading-screen {
    height: 70px;
    width: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #fff;
    text-align: center;
    border: solid #dcdcdc 1px;
    /*opacity: 0.5;*/
    border-radius: 5px;

}

.comm-loading {
    position: fixed;
    margin-top: -100px;
    margin-left: 50px;
}

.login {
    margin: 0 auto;
}

/*上传用户图片样式*/
.avatar-uploader,
.avatar-uploader-trigger,
.avatar {
    width: 150px;
    height: 150px;
}

.avatar-uploader {
    display: block;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
}

.avatar-uploader-trigger {
    display: table-cell;
    vertical-align: middle;
    font-size: 28px;
    color: #999;
}

.app-webkit-app-region {
    -webkit-app-region: drag
}

/* NestedTable */

.components-table-demo-nested .ant-table-expanded-row > td:last-child {
    padding: 0 48px 0 8px;
}

.components-table-demo-nested .ant-table-expanded-row > td:last-child .ant-table-thead th {
    border-bottom: 1px solid #e9e9e9;
}

.components-table-demo-nested .ant-table-expanded-row > td:last-child .ant-table-thead th:first-child {
    padding-left: 0;
}

.components-table-demo-nested .ant-table-expanded-row > td:last-child .ant-table-row td:first-child {
    padding-left: 0;
}

.components-table-demo-nested .ant-table-expanded-row .ant-table-row:last-child td {
    border: none;
}

.components-table-demo-nested .ant-table-expanded-row .ant-table-thead > tr > th {
    background: none;
}

.components-table-demo-nested .table-operation a:not(:last-child) {
    margin-right: 24px;
}

.components-table-demo-nested .ant-table-expanded-row:hover > td {
    background: #fbfbfb;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
    margin-top: -16px;

}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
}

/* :global(.myMenu) {
    border-bottom:none !important;
}
:global(.myMenu .ant-menu-item-active) {
    border-bottom:1px #ccc solid !important;
}
:global(.myMenu .ant-menu-item) {
    border-top:10px solid #ccc;
}
:global(.myMenu .ant-menu) {
    border-bottom:none !important;
}
:global(.myMenu .ant-menu-item-selected) {
    border-top: 10px solid #fc1a6e;
    border-bottom:none !important;
} */

.ant-empty-normal .ant-empty-description{
    text-align: center !important;
}
/* :global(.myMenu .ant-menu-item) {
    border:1px solid #ccc;
} */

.ant-table-content .ant-table-body .ant-table-thead > tr > th {
    /*background-color: #fafafa !important;*/

}

.ant-input{
    padding:4px 6px!important;
}


.loadding-main{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    outline: 0;
    z-index: 99999;
}
.loadding-main-b{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #999;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    outline: 0;
    z-index: 99999;
    opacity:0.5;
}

.loadding-main-content{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    outline: 0;
    z-index: 99999;
}

.loadding-main-content-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    text-align: center;
    border: solid #dcdcdc 1px;
    /*opacity: 0.5;*/
    border-radius: 5px;
    padding: 30px 50px 20px 50px;

}
ul{
    list-style: none;
    margin:0;
    padding:0
}
.chaineditul-store li{
    padding:10px 0;
    cursor: pointer;
}
.chainedit-sett .active{
    background-color:#E0E0E0;
}

/* .ant-layout, .ant-layout  .ant-tabs-bar {
    margin: 0 !important;   
} */



.comm-user-select{
    user-select: text;
    /*firefox浏览器*/
    -moz-user-select: text;
    /*safari、chrome浏览器*/
    -webkit-user-select: text; /*Safari中不支持该属性值，只能使用none或者text，或者是在html的标签属性中使用*/
    /*ie浏览器*/
    -ms-user-select: text;
}

.comm-cursor{
    cursor:pointer;
}

.comm-title-view{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.comm-title-right{
    text-align: right;
    width: 100px;
    margin-right: 10px;
}

.content-new-name{
    font-weight: bold;
    font-size: 20;
}


/* .comm-table-view .comm-table-pagination{
    width: 100%;
    background: white;
    position: absolute;
    right: 25px;
    bottom: 0px;
    padding-bottom: 20px;
}
.comm-table-view  .ant-pagination{
    position: absolute;
    right: 25px;
    bottom: 20px;
} */