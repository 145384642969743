@font-face {
  font-family: "iconfont"; /* Project id 4459661 */
  /* Color fonts */
  src: 
       url('iconfont.woff2?t=1714057851842') format('woff2'),
       url('iconfont.woff?t=1714057851842') format('woff'),
       url('iconfont.ttf?t=1714057851842') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xinxi:before {
  content: "\e764";
}

.icon-tongzhi1:before {
  content: "\e68e";
}

.icon-qiyefuwu:before {
  content: "\e640";
}

.icon-qiandai:before {
  content: "\e608";
}

.icon-huiyuan:before {
  content: "\e60f";
}

.icon-shangjiadianpu:before {
  content: "\ffbc";
}

.icon-kaoqinyichang:before {
  content: "\e791";
}

.icon-kehushengyin:before {
  content: "\e765";
}

.icon-naozhong:before {
  content: "\e63e";
}

.icon-dakarenwu:before {
  content: "\e627";
}

.icon-caigou:before {
  content: "\e60d";
}

.icon-tianshenpi:before {
  content: "\e604";
}

.icon-arrow-left:before {
  content: "\e694";
}

.icon-arrow:before {
  content: "\e695";
}

.icon-arrow-up:before {
  content: "\e696";
}

.icon-arrow-down:before {
  content: "\e697";
}

.icon-recharge:before {
  content: "\e693";
}

.icon-balance-o:before {
  content: "\e69a";
}

.icon-balance-list-o:before {
  content: "\e69c";
}

.icon-cart-o:before {
  content: "\e6c9";
}

.icon-chart-trending-o:before {
  content: "\e6a2";
}

.icon-cashier-o:before {
  content: "\e6a3";
}

.icon-credit-pay:before {
  content: "\e6a6";
}

.icon-coupon-o:before {
  content: "\e6a7";
}

.icon-cross:before {
  content: "\e6a9";
}

.icon-manager-o:before {
  content: "\e6bc";
}

.icon-minus:before {
  content: "\e6b3";
}

.icon-pending-payment:before {
  content: "\e6c1";
}

.icon-plus:before {
  content: "\e6c2";
}

.icon-search:before {
  content: "\e6b5";
}

.icon-scan:before {
  content: "\e6c4";
}

.icon-wap-home-o:before {
  content: "\e6ca";
}

.icon-todo-list-o:before {
  content: "\e6c8";
}

.icon-a-66logo:before {
  content: "\e6b9";
}

