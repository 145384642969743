.model-left{
    border: solid 1px #f2f2f2;
    height: 100%;
    width: 610px;
    margin-right: 20px;
}


.model-type-view{
    width: 200px;
    border-right: solid 1px #f2f2f2;
    height: 100%;
    overflow: auto;
}

.model-type-text{
    /* height: 30px; */
    /* width: 100px; */
    padding: 5px 0px;
    text-align: center;
    border-bottom: solid 1px #f2f2f2;
} 

.model-type-content{
    height: 100%;
    width: 100%;
    overflow: auto;
    
}

.model-goods-view{
    height: 100%;
    padding: 5px 0px 0px 10px;
    
}

.model-goods-content{
    margin-top: 10px;
    height: calc(100% - 42px);
    width: 100%;
    overflow: auto;
}

.model-goods-title{
    background-color:"#f2f2f2";
    padding: 5px 10px;

}
.model-goods-text{
    padding: 5px 10px;
    border-bottom: solid 1px #f2f2f2;
}

.model-rigth{
    border: solid 1px #f2f2f2;
    height: 100%;
    width: 100%;
    overflow: auto;
}


.model-selected-content{
    
    height: calc(100% - 42px);
    width: 100%;
    overflow: auto;
}

.model-selected-title{
    background-color:"#f2f2f2";
    padding: 5px 10px;

}
.model-selected-text{
    padding: 5px 10px;
    border-bottom: solid 1px #f2f2f2;
}