.t-table{
    width:100%;
    height: 100%;
    text-align: center;
    border-radius: 5px;
}

.t-table tr{
    border: solid 1px #ccc;
    height: 50px;
    border-radius: 5px;
}



.t-table-view{
    width:100%;
    height: 100%;
}


.t-table .t-table-title{
    width:10%;
    height: 100%;
}

.t-table-content {
    /*height: 300px;*/
    height: 90%;
    width: 100%;
    overflow: auto;
    /*overflow-y: scroll;*/
    -webkit-overflow-scrolling: touch;
}
.table-Name {
    width: 13%;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table-sim-note {
    width: 20%;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.t-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
}

.table-class-select{
    width: 10%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table-class-date{
    width: 12%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table-class-w-5{
    width: 5%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.table-class-w-8{
    width: 8%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.table-class-w-10{
    width: 10%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.table-class-w-12{
    width: 12%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.table-class-w-14{
    width: 14%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.table-class-w-15{
    width: 15%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table-class-w-20{
    width: 20%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.table-class-w-25{
    width: 25%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table-class-w-30{
    width: 30%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table-class-w-35{
    width: 35%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.table-class-w-40{
    width: 40%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab-class-opt{
    width: 200px;
}
.agreeinfo-table-name{
    width: 56px;
    padding: 0px!important;
    /*margin: 10px 1px!important;*/
}
.agreeinfo-table-time{
    width: 100%;
    padding: 0px!important;
    /*margin: 10px 1px!important;*/
}

.ant-table-scroll .ant-table-fixed .ant-table-thead tr th{
    padding: 10px 0px!important;
}
.active-collection-color{
    color:#fc1a6e;
    border-bottom:none
}
.active-collection{
    width: 270px;
    margin-bottom:10px;
    text-align:center;

}
.active-collection > div{
    width:calc((100% - 4px)/2);
    padding:10px 0;
    border: 1px solid #e8e8e8;
    border-radius:5px;
    cursor: pointer;
}
.active-collection > div:nth-last-child(1){
    /* border-left:1px solid #fafafa; */
}
.collective1{
    color:rgba(0, 0, 0, 0.65);
    /* background-color:#fafafa; */
}
.active-collection-backgr{
    background-color:#fafafa;
}
.ant-tabs-tab-personal .ant-tabs-no-animation{
    height:100% !important;
}
.ant-tabs-tab-personal .ant-tabs-tabpane-active{
    height:100% !important;
}
.ant-tabs-tab-personal .ant-tabs-content-no-animated{
    height:100% !important;
}