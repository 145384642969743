.ant-table td {
    white-space: nowrap;
}


.service-main-view-content{
    position: absolute;
    top: 60px;
    left: 15px;
    right: 15px;
    bottom: 0px;
    overflow: auto;
}