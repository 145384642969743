.imodal-view{
     position:relative;
 }

.imodal-colse-view{
    position: absolute;
    top: 15px;
    right: 15px;
    color:#666;
}

.imodal-view-footer-buttom{
    width: 100%;
    position: relative;
}

