* Response Layout
.container{padding-right:15px;padding-left:15px;margin:0 auto;background:#fff;}
.row{margin-right:-15px;margin-bottom:10px;margin-right:-15px;}
.container [class*="col-"],.row [class*="col-"]{float:left;padding-left:15px;
    padding-right:15px;position:relative;min-height:1px;}
.col-1{width:8.333333%}
.col-2{width:16.666666%}
.col-3{width:25%}
.col-4{width:33.333333%}
.col-5{width:41.666666%}
.col-6{width:50%}
.col-7{width:58.333333%}
.col-8{width:66.666666%}
.col-9{width:75%}
.col-10{width:83.333333%}
.col-11{width:91.666666%}
.col-12{width:100%}

@media (min-width:768px){
    .hidden-sm{display:none !important}
    .col-sm-1{width:8.333333%}.col-sm-2{width:16.666666%}.col-sm-3{width:25%}.col-sm-4{width:33.333333%} .col-sm-5{width:41.666666%} .col-sm-6{width:50%}.col-sm-7{width:58.333333%}.col-sm-8{width:66.666666%} .col-sm-9{width:75%} .col-sm-10{width:83.333333%} .col-sm-11{width:91.666666%} .col-sm-12{width:100%}
}
@media (min-width:992px){
    .hidden-md{display:none !important}
    .col-md-1{width:8.333333%}.col-md-2{width:16.666666%}.col-md-3{width:25%}.col-md-4{width:33.333333%} .col-md-5{width:41.666666%} .col-md-6{width:50%}.col-md-7{width:58.333333%}.col-md-8{width:66.666666%} .col-md-9{width:75%} .col-md-10{width:83.333333%} .col-md-11{width:91.666666%} .col-md-12{width:100%}
}
@media (min-width:1200px){
    .hidden-lg{display:none !important}
    .col-lg-1{width:8.333333%}.col-lg-2{width:16.666666%}.col-lg-3{width:25%}.col-lg-4{width:33.333333%} .col-lg-5{width:41.666666%} .col-lg-6{width:50%}.col-lg-7{width:58.333333%}.col-lg-8{width:66.666666%} .col-lg-9{width:75%} .col-lg-10{width:83.333333%} .col-lg-11{width:91.666666%} .col-lg-12{width:100%}
}
@media(max-width:767px){
    .hidden-xs{display:none !important;}
    .col-xs-1{width:8.333333%}.col-xs-2{width:16.666666%}.col-xs-3{width:25%}.col-xs-4{width:33.333333%} .col-xs-5{width:41.666666%} .col-xs-6{width:50%}.col-xs-7{width:58.333333%}.col-xs-8{width:66.666666%} .col-xs-9{width:75%} .col-xs-10{width:83.333333%} .col-xs-11{width:91.666666%} .col-xs-12{width:100%}
}

/** 文本对齐 */
.gra-text-r{text-align:right;}
.gra-text-l{text-align:left;}
.gra-text-d{text-align:inherit;}

/** 允许拖拽 */
.gra-drag-region{-webkit-app-region: drag;app-region:drag;}
.gra-no-drag{app-region: no-drag;-webkit-app-region: no-drag;}

/** 允许选择 */
.gra-select-text{user-select:text}
.gra-select-all{user-select:all}
.gra-select-none{user-select:none}



body{border-radius:5px;box-shadow:0 0 10px #fff;overflow:hidden;}

/* 边框 */
.gra-radius-border{background:#FFF;}
.gra-border-pd15{padding:15px;}

/* 表格 */
.gra-table{border:solid 1px #dcdcdc;}
.gra-table table{border-collapse:collapse;border-spacing:inherit;empty-cells:show;border-radius:5px;}
.gra-table table td{border-left:solid 1px #dcdcdc;border-bottom:solid 1px #dcdcdc;background:#FFF;line-height:20px;padding:10px 0;}
.gra-table table tr td:first-child{border-left:0;}
.gra-table table td{border-right:0;}
.gra-table table thead td{background:#F3F3F3;}
.gra-table table thead{line-height:40px;height:40px;overflow:hidden;background:#F3F3F3;}
.gra-table .center{text-align:center;}

/** 联想搜索框 */
.gra-smart-search{width:200px;overflow:hidden;height:32px;position:relative;padding-right:2px;overflow: hidden;}
.gra-smart-search .gra-smart-search-btn{position:absolute;right:0;top:0;bottom:0;margin:auto 0;width:50px;background:#fc1a6e;z-index: 10}
.gra-smart-search .ant-select-selection{width:190px;}

/** 按钮组 */
.gra-btn{margin-left:15px;}
.gra-btn-primary{background:#fc1a6e;color:#FFF;}
.gra-btn-primary:hover {color: #FFF;background-color: #B166FC;border-color: #fff;}
.gra-btn-primary:focus{background-color:#9145DE;}
.gra-btn-second{background-color:#EC6565;border-color:#fff;color:#FFF;}
.gra-btn-second:hover {color: #FFF;background-color: #EE7B7B;border-color: #ccc;}
.gra-btn-second:focus{background-color:#D05656;}
.gra-btn-default{background:#FFF;border-color:#D8D8D8;}
.gra-btn-default:hover{background-color:#F3F4F6;}
.gra-btn-default:focus{background-color:#D8D8D8;}
.gra-btn-primary span,.gra-btn-second span{color:#FFF;}

/** 右对齐列表 */
.gra-ra-list,.gra-gra-list ul{list-style:none;margin:0;padding:0;}
.gra-ra-list label{text-align:right;width:100px;display:inline-block;margin-right:10px;}

