/* .organzation{
    position: relative;
    height: 100%;
    overflow: auto;
    display:flex;
    margin:15px 20px;
    justify-content: space-between;
} */

.organzation .organ-left {
    width:270px;
    /* min-width:290px; */
   
}
.organzation .organ-right {
    width: calc(70% - 60px);
    /* min-width:650px; */
    /* margin-left:20px; */
    
}
.organzation .organ-left,.organzation .organ-right{
    /* height:560px; */
    /* border:1px solid #d9d9d9; */
}
.head-organ-right{
    height:65px;
    border-bottom:1px solid #d9d9d9;
}
.head-organ-right{
    display:flex;
    align-items: center;
    padding:0 20px;
    justify-content: space-between;
}

.comm-flex-row-start{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.comm-padding-top-15{
    padding-top:15px;
}
.comm-flex-row-start > span{
    margin-right:8px;
}
.arrow{
    cursor: pointer;;
    color:#222;
    width:40px;
    height:30px;
    border:1px #d8d8d8 solid;
    text-align: center;
    line-height: 30px;
}
.comm-flex-row-start .btn{
    width:65px;
    height:35px;
    text-align:center;
    line-height:35px;
    background-color:#fff;
    border:1px solid rgb(217, 217, 217);
    border-radius:5px;
    cursor: pointer;
}
.shopInfocityselect .citySelect{
    width:250px;
}