.mod-traffic-light {
    -webkit-app-region: no-drag;
    position: fixed;
    right: 15px;
    top: 0;
    line-height: 40px;
    max-width: 78px;
}

.mod-traffic-light-linux {
    left: 15px;
    right: inherit;
}

.mod-traffic-light i {
    margin-left: 5px;
    font-size: 14px;
}

/*
.mod-traffic-light .anticon-minus-circle{color: #438019;}
.mod-traffic-light .anticon-plus-circle{color: #ffb812;}
.mod-traffic-light .anticon-close-circle{color: #ff5548;}
*/