.role-model-btn-view {
    position: relative;
    height: 30px;
}

.role-model-btn-view div {
    position: absolute;
    right: 80px;
    top: 0px;
}

.vip-modal-view {
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
    /*overflow-y: scroll;*/
    /*-webkit-overflow-scrolling: touch;*/
}

.vip-modal-extract-view {
    width: 100%;
    /*height: 17vh;*/
    /*overflow-y: scroll;*/
    /*-webkit-overflow-scrolling: touch;*/
}

.table-phone {
    width: 15%;
    font-size: 13px;
}

.table-Name {
    width: 13%;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table-Name-30 {
    width: 30%;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table-RealName {
    width: 13%;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.position-title-view {
    margin-top: 10px;
    border-radius: 5px;
    /*border: solid #ccc 1px;*/
}

.position-title-item-view {
    padding: 0px 20px 20px 0px;
}

.position-text {
    width: 100px;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
}
/* .shopSetting .ant-tabs-card .ant-tabs-nav{display:block;padding:-5px;text-align:center;border-bottom:solid 1px #E0E0E0;}
.shopSetting .ant-tabs-card .ant-tabs-tab-arrow-show{display:none;}
.shopSetting .ant-tabs-card .ant-tabs-nav-container-scrolling{padding-left:0;padding-right:0}
.shopSetting .ant-tabs-card > .ant-tabs-bar{background:#F2F3F5;border-radius:5px 5px 0 0;}
.shopSetting .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab{width:inherit !important;border-left:0;border-top:0;
    border-color:#E0E0E0;border-radius:0;background:transparent;color:#666;}
.shopSetting .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active{background:#FFF;border-bottom:0;padding-bottom:2px;color:#333;}
.shopSetting .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab:first-child{border-radius:5px 0 0 0;}
.shopSetting .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab:last-child{border-right:0;border-radius:0 5px 0 0}
.shopSetting .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab{
    width:16.667% !important
} */
.title{
    float: left;
    width: 140px;
    text-align: right;
    margin-top: 5px
}


.position-room{
    padding: 15px 0px 0px 20px ;
    background:#fff;
}
.tag-name .ant-tag{
    height: 30px;
    line-height: 30px;
}
.ant-btn-primary span{
    color:#fff;
}
.container  .tag-name  .ant-input-disabled{
    text-align:center ;
    color:black;
    background-color:#fff;
}
.container  .tag-name  .ant-input-disabled:hover{
    cursor: pointer;
    border-color:none;
}
.flex-vipremark{
    width:100%;
    /* margin:8px 0; */
    display:flex;
    /* justify-content: space-between; */
}
.flex-vipremark > :nth-child(1){
    /* width:193px; */
    text-align:right;
}
.flex-vipremark > :nth-child(2){
    width:100%;
    margin-left:18px;

}


.picture-wdith img{
    margin-right:10px;
}
.flex-addinfo{
    width: 77%;
    margin:15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex-addinfo > :nth-child(1){
    width:70px;

}
.vipinfo-fixed{
    width: 35%;
    position: fixed;
    bottom: 8px;
    background-color:white;
}
.fixed-save{
    width: 31%;
    margin-top: 20px;
    position: fixed;
    bottom: -50px;
    background-color: white;
}
.imgPicPrew{
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0;
    right: 0;
    z-index: 2;
    margin: auto;
    width:1100px;
    height:650px;
    background-color: rgba(0, 0, 0, 0.45);
    
}
/* .listPicture ul{
    width:585px;
    padding:0;
    position:absolute;
    left:0;
    top:0;
    height:360px;
} */
/* .listPicture ul li{
    display:inline-block;
    font-size:0;
    
} */
/* .listPicture ul li img{
    width:100%;
    height:100%;
} */
/* .listPicture .box{
    position:relative;
    overflow: hidden;
    width:585px;
    height:360px;
    margin:12% auto;
    list-style: none;
    background-color:red;
} */


.ant-col{
    padding:0;
}
.ant-checkbox + span{
    font-size:13px;
}
.ant-checkbox-wrapper{
    margin:5px 0;
}
.ant-timeline-item-last > .ant-timeline-item-tail{
    display:block;
}
.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item{
    width:271px;
}

.vipinfo-remark-flex > div:nth-child(1){
    width:80px;
}
.list-vipremark .ant-timeline-item{
    padding-bottom:5px;
}
.ant-timeline .ant-timeline-item-last > .ant-timeline-item-tail{
    display:block;
}
.arrow{
    cursor: pointer;;
    color:#222;
    width:40px;
    height:30px;
    border:1px #d8d8d8 solid;
    text-align: center;
    line-height: 30px;
}
#vipremarkBorder .ant-tabs-no-animation{
    height:100%;
}
#vipremarkBorder .ant-tabs-top-content{
    height:91%;
}
#vipremarkBorder .ant-tabs-tabpane-active{
    height:100%;
}
#vipremarkBorder .vipeditinfo{
    height:100%;
}
#vipremarkBorder .addtype,#vipremarkBorder .preview,#vipremarkBorder .editorcontent{
    height:90%;
}

  
.dragSort{
    /* position: static; */
    /* top:300px; */
    position:relative;
    width: 100%;
    margin:10px auto;
    /* overflow:auto; */
}
.dragSort > div{
    position:relative;
    float:left; 
    width:100px; 
    height:65px; 
    margin:5px 8px;
    background:white; 
    border:1px solid #ccc; 
    line-height:65px; 
    text-align:center;
    border-radius:5px;
    
}
.dragSort > .noNumberArrItem{
    margin:15px 8px;
}
.dragSort > div span{
    position: absolute;
    width:21px;
    height:21px;
    line-height:21px;
    text-align:center;
    border-radius:50%;
    top:22px;
    left:-12px;
    background-color:#ccc;
}
.dragSort > div i{
    position: absolute;
    top: 2px;
    right: 3px;
    text-align: center;
    border-radius: 50%;
}
.dragSort > div .marginLe{
    text-align: center;
    height:40%;
}
.storenogroup .addtop-style{
    position:relative;
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.storenogroup .addtop-style .icons{
    display:flex;
    
}
.storenogroup .addtop-style .arrow{
    width:30px;
    margin:0 3px;
}
.addtop-style::after{
    position:absolute;
    top:40px;
    left:0;
    content:'';
    width:100%;
    height:2px;
    background-color:#ECECEC;
}
.list-ul{
    margin-top:10px;
}
.list-ul > li{
    padding:8px;
    border-bottom:1px solid #E1E1E1;
}
.list-ul > li:first-child{
    border-top:1px solid #E1E1E1;
}
.list-ul > li > span{
    display:inline-block;
}