.turnover {
  background: -webkit-linear-gradient(
    left top,
    #b0b0ff,
    #5c5cd7
  ); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(
    bottom right,
    #b0b0ff,
    #5c5cd7
  ); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(
    bottom right,
    #b0b0ff,
    #5c5cd7
  ); /* Firefox 3.6 - 15 */
  background: linear-gradient(
    to bottom right,
    #b0b0ff,
    #5c5cd7
  ); /* 标准的语法 */
}

.order {
  background: -webkit-linear-gradient(
    left top,
    #ffa8be,
    #fe5681
  ); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(
    bottom right,
    #ffa8be,
    #fe5681
  ); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(
    bottom right,
    #ffa8be,
    #fe5681
  ); /* Firefox 3.6 - 15 */
  background: linear-gradient(
    to bottom right,
    #ffa8be,
    #fe5681
  ); /* 标准的语法 */
}

.member {
  background: -webkit-linear-gradient(
    left top,
    #94ffe8,
    #1dd5ae
  ); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(
    bottom right,
    #94ffe8,
    #1dd5ae
  ); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(
    bottom right,
    #94ffe8,
    #1dd5ae
  ); /* Firefox 3.6 - 15 */
  background: linear-gradient(
    to bottom right,
    #94ffe8,
    #1dd5ae
  ); /* 标准的语法 */
}

.achievement {
  background: -webkit-linear-gradient(
    left top,
    #ffe29a,
    #facb57
  ); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(
    bottom right,
    #ffe29a,
    #facb57
  ); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(
    bottom right,
    #ffe29a,
    #facb57
  ); /* Firefox 3.6 - 15 */
  background: linear-gradient(
    to bottom right,
    #ffe29a,
    #facb57
  ); /* 标准的语法 */
}
.returnAmount {
  background: -webkit-linear-gradient(
    left top,
    #a6a4a9,
    #6c6871
  ); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(
    bottom right,
    #a6a4a9,
    #6c6871
  ); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(
    bottom right,
    #a6a4a9,
    #6c6871
  ); /* Firefox 3.6 - 15 */
  background: linear-gradient(
    to bottom right,
    #a6a4a9,
    #6c6871
  ); /* 标准的语法 */
}

.page-sliding {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  /* overflow-y: scroll; */
}

.padding20 {
  background: #fff;
  padding: 20px;
  margin-bottom: 10px;
}

.header {
  background: #fff;
  padding: 5px 20px;
  overflow: hidden;
  margin-bottom: 10px;
}

.boxContent {
  height: 191px;
  display: flex;
  justify-content: space-between;
}

.box {
  width: 23%;
  height: 150px;
  padding: 10px;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.memberCard {
  width: 19%;
  height: 110px;
  padding: 10px;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cardBox {
  width: 20%;
  height: 110px;
  padding: 10px;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.number {
  font-size: 24px;
}

.color {
  color: red;
}

.size {
  font-size: 12px;
  padding-top: 10px;
  border-top: 1px #fff solid;
}

.memberSmall {
  color: #767f88;
  font-size: 12px;
}

.memberBig {
  font-size: 28px;
  margin-top: 20px;
}

.pieContent {
  height: 360px;
}

.flexBox {
  display: flex;
  justify-content: space-between;
}

.tableContent {
  height: 280px;
}

.memberContent {
  height: 300px;
}

.memberBox {
  width: 150px;
  padding-top: 10px;
  text-align: center;
}

.orderContent {
  height: 520px;
}

.orderBox {
  width: 48%;
  height: 110px;
  padding: 10px;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bigSize {
  font-size: 24px;
}

.orderTrendContent {
  height: 400px;
}

.memberSummaryContent {
  height: 520px;
}

.memberChartBox {
  width: 50%;
}

.performContent {
  height: 440px;
}

.turnoverContent {
  height: 320px;
  overflow: hidden;
}
.empRankContent {
  height: 450px;
}

.turnoverTableContent {
  height: 435px;
  
}

.re-sliding-root {
  position: relative;
  width: 100%;
  height: 100%;
}

.re-sliding-title {
  /* background: #fff; */
  width: 100%;
  height: 45px;
}
.re-sliding-content {
  position: absolute;
  width: 100%;
  top: 55px;
  left: 0px;
  bottom: 0px;
  overflow: auto;
}
.borderBottom {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.list-turnover {
  list-style: none;
}
.list-turnover > li {
  margin-bottom: 20px;
}
.colorA44CFC {
  color: #fc1a6e;
  cursor: pointer;
}
.fontstyle {
  font-weight: bold;
}
.debt_marright {
  margin-right: 20px;
}
.onselectbtn > div {
  margin: 0 10px;
  padding: 5px 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  cursor: pointer;
}
.active {
  font-weight: bold;
  background-color: white;
}
.notactive {
  font-weight: normal;
  background-color: #fafafa;
}
.activeClassfly {
  font-weight: bold;
  border: 1px solid #eee;
  border-radius: 20px;
  background-color: #fafafa;
}
div.notactiveClassfly {
  font-weight: normal;
  border: none;
  background-color: white;
}
.liststyle {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  border-bottom: 1px solid #eee;
}
.liststyle > li {
  width: 88px;
  margin-top: 15px;
  padding: 8px 0;
  border: 1px solid #eee;
  border-bottom: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.personal-parenent {
  overflow: hidden;
  border: 1px solid #eee;
  border-bottom: none;
  border-top: none;
  padding: 10px 0;
}

.personal-fee,
.personal-forma {
  float: left;
  margin: 10px 30px 10px 20px;
}
.ant-table-tbody > tr > td {
  word-break: break-word;
}
.ant-table-tbody > tr > td.hiddenFont {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-drawer-body {
  padding-top: 10px;
}
.ant-table-tbody thead tr th > div {
  font-weight: bold;
}
.notclassfily {
  overflow: hidden;
  padding: 10px 0;
}
.ul_listnotclassfily .marginBottom10 {
  margin-bottom: 10px;
}

/* 会员报表样式 */
.vipreport {
}
.vipreportflex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.vipreport .vipreport-title ul {
  list-style: none;
  margin: 50px auto;
  padding-left: 0;
}
.iconbackgroundcolor {
  margin-right: 8px;
  padding: 10px 13px;
  text-align: center;
  border-radius: 50%;
  background-color: #fc1a6e;
}
.vipreport .height220 {
  height: 220px;
}
.vipreport .absoluteTop0 {
  top: 0;
}
.vipreport .height185 {
  height: 158px;
  padding: 0;
}
.vipreport .vipreportleft > li + li {
  border-left: 1px solid #ccc;
  padding: 0px 0 0 26px;
}
.positionsticky {
  position: sticky;
  left: 0;
  top: -8px;
  z-index: 999;
}
.list-turnover li {
  font-size: 12px;
}
.scroll-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  overflow-y: auto; 
  -webkit-overflow-scrolling: touch;
}
#root .ant-layout-content {
  overflow-y: hidden;
  margin-top: 15px;
}
.sourceOriginl{
  width:15%;
  margin-top:30px;
  margin-top: 30px;
  float: left;
  margin-left: 173px;

}
.sourceOriginl >li{
  display:flex;
  justify-content: space-between;
  min-width:130px;
  margin:5px 0;
}
