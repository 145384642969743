.bonus-line-item{
    margin-top: 10px;
}


  .union-plan-title {
    position: relative;
    width: 100%;
    height: 50px;
}

.union-plan-title div {
    position: absolute;
    right: 10px;
}
.addPlan-modal-line{
    overflow: hidden;
    margin-bottom: 10px
}
.actDetail-modal-line{
    overflow: hidden;
    margin-bottom: 10px;
}
.addPlan-modal-line-span{
    float: left;
    width: 130px;
    margin-top: 5px
}
.actDetail-modal-line-span{
    float: left;
    width: 100px;
}
.addPlan-modal-line-input{
    width: 70%;
    margin-right: 10px
}
.addPlan-modal-line-input-price{
    width: 80px;
    margin-right: 10px
}
.activity {
    background: -webkit-linear-gradient(left top, #fc1a6e, #FFA8A8); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(bottom right, #fc1a6e, #FFA8A8); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(bottom right, #fc1a6e, #FFA8A8); /* Firefox 3.6 - 15 */
    background: linear-gradient(to bottom right, #fc1a6e, #FFA8A8); /* 标准的语法 */
}
.bgActivity{
    background: url('../../../images/phone.png') no-repeat;
    background-size: 285px 530px;
}

  .el_tableDiv::-webkit-scrollbar{
    width: 15px;
    height: 10px;
    background-color: #f5f7fa;
  }
  /*滚动条的轨道*/
  .el_tableDiv::-webkit-scrollbar-track{
    /*box-shadow: inset 0 0 5px rgba(0,0,0,.3);*/
    background-color: #f5f7fa;
   
  }
  /*滚动条的滑块按钮*/
  .el_tableDiv::-webkit-scrollbar-thumb{
    /* border-radius: 10px; */
    background-color: #ccc;
    /*box-shadow: inset 0 0 5px #000;*/
  }
  /*滚动条的上下两端的按钮*/
  .el_tableDiv ::-webkit-scrollbar-button{
    height: 15px;
    width: 15px;
    background-color: #ccc;
  }
  .el_tableDiv{
    overflow-y:auto;
    overflow-x:hidden
  }
  .totalBox{
    padding: 5px 30px;
    margin-bottom: 10px;
    border: 1px solid #F9B7D8;
    border-radius: 4px;
    background: #FCD9E9;
    font-size: 12px
  }
  .totalBox span b{
    font-size: 14px
  }


  .menber-info-view{
    width:100%;
    height:60px;
    background:#FCD9E9;
    border-radius:4px;
    border:1px solid #F9B7D8;
    margin-bottom: 10px;
    margin-top: -20px;
  }