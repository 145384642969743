.tab_OrderNo {
    width: 15%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab_OrderTime {
    width: 15%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab_content {
    width: 15%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab_name {
    width: 15%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab_menber {
    width: 20%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab_No {
    width: 25%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab_na {
    width: 12%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab_count {
    width: 25%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab_close {
    width: 10%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab_time {
    width: 30%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.act-code-group-root {
    margin-top: 30px;
}

.act-code-group input {
    margin-top: 10px;
    width: 50px;
    margin-right: 10px;
    text-align: center;
    /*background-color:#00A699;*/
    border: solid 1px #ccc;
}

input {
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ant-table td {
    white-space: nowrap;
}

.tab_cardNo {
    width: 12%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tab_tc {
    width: 30%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.time{
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.extra{
    width: 30%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.items .ant-tabs-card .ant-tabs-nav{display:block;padding:-5px;text-align:center;border-bottom:solid 1px #E0E0E0;}
.items .ant-tabs-card .ant-tabs-tab-arrow-show{display:none;}
.items .ant-tabs-card .ant-tabs-nav-container-scrolling{padding-left:0;padding-right:0}
.items .ant-tabs-card > .ant-tabs-bar{background:#F2F3F5;border-radius:5px 5px 0 0;}
.items .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab{width:inherit !important;border-left:0;border-top:0;
    border-color:#E0E0E0;border-radius:0;background:transparent;color:#666;}
.items .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active{background:#FFF;border-bottom:0;padding-bottom:2px;color:#333;}
.items .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab:first-child{border-radius:5px 0 0 0;}
.items .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab:last-child{border-right:0;border-radius:0 5px 0 0;}
.items .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab{
    width:50% !important
}

.message-mzl-title{
    position: absolute;
    top: 50px;
    left: 6px;
    z-index: 888;
}
.masstext-in {
    display:flex;
}
.masstext-in .flex-date{
    display:flex;
    margin:10px 0;
    align-items: center;
}
.flex-date > div:nth-child(1){
    margin-right:9px;
}
.masstext-in .textline textarea{
    resize:none;
    outline:none;
    border:none;
}
.masstext-in .textline textarea::-webkit-scrollbar {
    display: none;
}
